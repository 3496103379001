import React, { useState } from "react";
import { propStyles, useStyles } from "../../assets/styles.js";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent
} from "@mui/material";
import MaterialTable from "material-table";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

const DisplayPoojaList = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [poojaId, setPoojaId] = useState();
  const [poojaName, setPoojaName] = useState();
  const [status, setStatus] = useState("");
  const [modalType, setModalType] = useState(""); // "view" or "edit"
  const [error, setError] = useState({});
  const [expertiesData, setExpertiesData] = useState([ // Product data in state
    {
      _id: "1", 
      title: "Pooja 1", 
      astrologer: "Astrologer 1", 
      customerName: "Customer 1", 
      customerNumber: "1234567890", 
      status: "Pending"
    },
    {
      _id: "2", 
      title: "Pooja 2", 
      astrologer: "Astrologer 2", 
      customerName: "Customer 2", 
      customerNumber: "9876543210", 
      status: "Shipped"
    },
    {
      _id: "3", 
      title: "Pooja 3", 
      astrologer: "Astrologer 3", 
      customerName: "Customer 3", 
      customerNumber: "5555555555", 
      status: "Delivered"
    }
  ]);

  const handleOpen = (rowData, type) => {
    setOpen(true);
    setPoojaId(rowData._id);
    setPoojaName(rowData.title);
    setStatus(rowData.status);
    setModalType(type); // Set the modal type as "view" or "edit"
  };

  const handleClose = () => {
    setPoojaId("");
    setPoojaName("");
    setStatus("");
    setOpen(false);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log(`Updated Pooja: ${poojaName}, Status: ${status}`);
      handleClose();
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!poojaName) {
      handleError("poojaName", "Please enter Pooja name");
      isValid = false;
    }
    return isValid;
  };

  const handleError = (input, value) => {
    setError((prev) => ({ ...prev, [input]: value }));
  };

  const handleStatusChange = (e, poojaId) => {
    const newStatus = e.target.value;

    // Update the status in the productData array
    const updatedData = expertiesData.map((pooja) =>
      pooja._id === poojaId ? { ...pooja, status: newStatus } : pooja
    );

    // Update the state with the modified product data
    setExpertiesData(updatedData);  // Assuming `productData` is stored in the state
  };

  const displayTable = () => {
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <MaterialTable
            title="Pooja List"
            data={expertiesData}
            columns={[
              {
                title: "S.No",
                editable: "never",
                render: (rowData) => expertiesData.indexOf(rowData) + 1,
              },
              { title: "Pooja Name", field: "title" },
              { title: "Astrologer Name", field: "astrologer" },
              { title: "Customer Name", field: "customerName" },
              { title: "Customer Number", field: "customerNumber" },
              {
                title: "Status",
                field: "status",
                render: (rowData) => (
                  <div>
                    <select
                      className={classes.statusDropdown}
                      value={rowData.status}
                      onChange={(e) => handleStatusChange(e, rowData._id)} 
                      style={{
                        backgroundColor:
                          rowData.status === "Pending"
                            ? "#FFD700" // Gold
                            : rowData.status === "Shipped"
                            ? "#90EE90" // Light Green
                            : "#D3D3D3", // Grey for Delivered
                      }}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                  </div>
                ),
              },
            ]}
            options={propStyles.tableStyles}
            style={{ fontSize: "1.0rem" }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit details",
                onClick: (event, rowData) => handleOpen(rowData, "edit"),
              },
              {
                icon: "visibility",
                tooltip: "View details",
                onClick: (event, rowData) => handleOpen(rowData, "view"),
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  };

  const viewModal = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <div className={classes.headingContainer}>
                <div className={classes.heading}>View Pooja</div>
              </div>
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Pooja Name"
                value={poojaName}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Astrologer Name"
                value={expertiesData.find((pooja) => pooja._id === poojaId)?.astrologer}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Customer Name"
                value={expertiesData.find((pooja) => pooja._id === poojaId)?.customerName}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Customer Number"
                value={expertiesData.find((pooja) => pooja._id === poojaId)?.customerNumber}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="status-select">Status</InputLabel>
                <Select
                  labelId="status-select"
                  value={status}
                  variant="outlined"
                  disabled
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Shipped">Shipped</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                className={classes.closeButton}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const editModal = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <div className={classes.headingContainer}>
                <div className={classes.heading}>Edit Pooja</div>
              </div>
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Pooja Name"
                value={poojaName}
                onChange={(e) => setPoojaName(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!error.poojaName}
                helperText={error.poojaName}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-status">Select Status</InputLabel>
                <Select
                  labelId="select-status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  variant="outlined"
                  error={!!error.status}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Shipped">Shipped</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                </Select>
                <div className={classes.errorstyles}>{error.status}</div>
              </FormControl>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <div onClick={handleSubmit} className={classes.submitbutton}>
                Submit
              </div>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <div onClick={handleClose} className={classes.denyButton}>
                Cancel
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className={classes.container}>
      {!expertiesData.length ? (
        <CircularProgress />
      ) : (
        <div className={classes.box}>
          {displayTable()}
          {modalType === "view" ? viewModal() : editModal()}
        </div>
      )}
    </div>
  );
};

export default DisplayPoojaList;
