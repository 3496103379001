import React, { useState } from "react";
import { DialogContent, Dialog, Card, CardContent } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import WalletInvoice from "./WalletInvoice";
import { ApiRequest } from "../../../utils/apiRequest";
import { Button } from "@material-ui/core";

const InvoiceModal = ({ invoiceModalOpen, updateState, astrologerId }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [invoiceData, setInvoiceData] = useState(null);
  const handleChange = (date) => {
    setInvoiceData(null);
    setSelectedDate(date);
  };
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const getInoiceData = async () => {
    try {
      const dateFrom = new Date(selectedDate);
      dateFrom.setDate(1); // Set to the first day of the selected month
      const dateTo = new Date(selectedDate);
      dateTo.setMonth(dateTo.getMonth() + 1); // Move to the next month
      dateTo.setDate(0); // Set to the last day of the current month
      console.log({
        dateFrom,
        dateTo,
      });
      const response = await ApiRequest.postRequest({
        url: "http://97.74.83.200:4000/api/app/astrologer/get_astrologer_wallet_summary_by_date",
        header: "json",
        data: {
          dateFrom,
          dateTo,
          astrologerId,
        },
      });
      console.log(response);
      if (response?.success) {
        setInvoiceData(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={invoiceModalOpen}
      onClose={() => {
        setInvoiceData(null);
        updateState({ invoiceModalOpen: false, astrologerId: null });
      }}
    >
      <DialogContent
        style={{
          height: "30vh",
        }}
      >
        <Card>
          <CardContent>
            <label>Select Month and Year:</label>
          </CardContent>
          <CardContent>
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              selected={selectedDate}
              onChange={handleChange}
              showMonthYearPicker
              renderMonthContent={renderMonthContent}
              dateFormat="MM/yyyy"
            />
          </CardContent>
          <CardContent>
            <Button
              onClick={getInoiceData}
              type="button"
              style={{ backgroundColor: "green", color: "white" }}
            >
              Get
            </Button>
            {/* <PDFDownloadLink
              document={<WalletInvoice />}
              fileName="somename.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink> */}
          </CardContent>
          <CardContent>
            {invoiceData && (
              <span>Monthly Balance: {invoiceData?.totalAmountByDate}</span>
            )}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceModal;
