import React, { useState } from "react";
import { propStyles, useStyles } from "../../assets/styles.js";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent
} from "@mui/material";
import MaterialTable from "material-table";
import CloseIcon from "@mui/icons-material/Close"; // Import the close icon
import Swal from "sweetalert2";

const DisplayProductList = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [status, setStatus] = useState("");
  const [modalType, setModalType] = useState(""); // "view" or "edit"
  const [error, setError] = useState({});
  const [productData, setProductData] = useState([ // Move productData to state
    {
      _id: "1",
      title: "Pooja 1",
      astrologer: "Astrologer 1",
      customerName: "Customer 1",
      customerNumber: "1234567890",
      status: "Pending",
      price: "$100"
    },
    {
      _id: "2",
      title: "Pooja 2",
      astrologer: "Astrologer 2",
      customerName: "Customer 2",
      customerNumber: "9876543210",
      status: "Shipped",
      price: "$150"
    },
    {
      _id: "3",
      title: "Pooja 3",
      astrologer: "Astrologer 3",
      customerName: "Customer 3",
      customerNumber: "5555555555",
      status: "Delivered",
      price: "$200"
    }
  ]);

  const handleOpen = (rowData, type) => {
    setOpen(true);
    setProductId(rowData._id);
    setProductName(rowData.title);
    setStatus(rowData.status);
    setModalType(type);  // Set the modal type ("view" or "edit")
  };

  const handleClose = () => {
    setProductId("");
    setProductName("");
    setStatus("");
    setOpen(false);
  };

  const validation = () => {
    let isValid = true;
    if (!productName) {
      handleError("productName", "Please input product name");
      isValid = false;
    }
    return isValid;
  };

  const handleError = (input, value) => {
    setError((prev) => ({ ...prev, [input]: value }));
  };

  const handleSubmit = () => {
    if (validation()) {
      console.log(`Updated product: ${productName}, status: ${status}`);
      handleClose();
    }
  };

  const handleStatusChange = (e, productId) => {
    const newStatus = e.target.value;

    // Update the status in the productData array
    const updatedData = productData.map((product) =>
      product._id === productId ? { ...product, status: newStatus } : product
    );

    // Update the state with the modified product data
    setProductData(updatedData);  // Assuming `productData` is stored in the state
  };

  const handleClickOpen = (rowData) => {
    Swal.fire({
      title: "Are you sure to Change the Status?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!"
    }).then((result) => {
      if (result.isConfirmed) {
        const newStatus =
          rowData.status === "Pending"
            ? "Shipped"
            : rowData.status === "Shipped"
            ? "Delivered"
            : "Pending";
        console.log(`Changed status of ${rowData.title} to ${newStatus}`);
      }
    });
  };

  function displayTable() {
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <MaterialTable
            title="Product List"
            data={productData}
            columns={[
              {
                title: "S.No",
                editable: "never",
                render: (rowData) =>
                  Array.isArray(productData)
                    ? productData.indexOf(rowData) + 1
                    : "N/A"
              },
              { title: "Product Name", field: "title" },
              { title: "Product Price", field: "price" },
              { title: "Astrologer Name", field: "astrologer" },
              { title: "Customer Name", field: "customerName" },
              { title: "Customer Number", field: "customerNumber" },
              {
                title: "Status",
                field: "status",
                render: (rowData) => (
                  <div>
                    <select
                      className={classes.statusDropdown}
                      value={rowData.status}
                      onChange={(e) => handleStatusChange(e, rowData._id)}  // Pass product id here
                      style={{
                        backgroundColor:
                          rowData.status === "Pending"
                            ? "#FFD700"
                            : rowData.status === "Shipped"
                            ? "#90EE90"
                            : "#D3D3D3"
                      }}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                  </div>
                )
              }
            ]}
            options={propStyles.tableStyles}
            style={{ fontSize: "1.0rem" }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit details",
                onClick: (event, rowData) => handleOpen(rowData, "edit")
              },
              {
                icon: "visibility",
                tooltip: "View details",
                onClick: (event, rowData) => handleOpen(rowData, "view")
              }
            ]}
          />
        </Grid>
      </Grid>
    );
  }

  function viewModal() {
    const showViewForm = () => {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <div className={classes.headingContainer}>
              <div className={classes.heading}>View Product</div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Product Name"
              value={productName}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Product Price"
              value={productData.find((product) => product._id === productId)?.price}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Astrologer Name"
              value={productData.find((product) => product._id === productId)?.astrologer}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Customer Name"
              value={productData.find((product) => product._id === productId)?.customerName}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Customer Number"
              value={productData.find((product) => product._id === productId)?.customerNumber}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Status</InputLabel>
              <Select
                labelId="select-label"
                value={status}
                variant="outlined"
                disabled
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Shipped">Shipped</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              className={classes.closeButton}
              style={{
                position: "absolute",
                top: 10,
                right: 10
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    };

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>{showViewForm()}</DialogContent>
      </Dialog>
    );
  }

  function editModal() {
    const showEditForm = () => {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <div className={classes.headingContainer}>
              <div className={classes.heading}>Edit Product</div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Enter Product Name"
              error={error.productName ? true : false}
              helperText={error.productName}
              value={productName}
              onFocus={() => handleError("productName", null)}
              onChange={(event) => setProductName(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Select Status</InputLabel>
              <Select
                labelId="select-label"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                variant="outlined"
                error={!!error.status}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Shipped">Shipped</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <div onClick={handleSubmit} className={classes.submitbutton}>
              Submit
            </div>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <div onClick={handleClose} className={classes.denyButton}>
              Cancel
            </div>
          </Grid>
        </Grid>
      );
    };

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>{showEditForm()}</DialogContent>
      </Dialog>
    );
  }

  function renderModal() {
    if (modalType === "view") {
      return viewModal();  // Show view modal
    } else if (modalType === "edit") {
      return editModal();  // Show edit modal
    }
    return null;
  }

  return (
    <div className={classes.container}>
      {productData.length === 0 ? (
        <CircularProgress />
      ) : (
        <div className={classes.box}>
          {displayTable()}
          {renderModal()}  {/* Display the modal */}
        </div>
      )}
    </div>
  );
};

export default DisplayProductList;
