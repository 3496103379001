import React, { useState } from "react";
import { propStyles, useStyles } from "../../assets/styles.js";
import {
  Grid,
  TextField,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent
} from "@mui/material";
import MaterialTable from "material-table";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

const DisplayBookedRemidies = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [remidyId, setRemidyId] = useState();
  const [remidyName, setRemidyName] = useState();
  const [modalType, setModalType] = useState(""); // "view" or "edit"
  const [error, setError] = useState({});
  const [expertiesData, setExpertiesData] = useState([
    {
      _id: "1",
      categoryTypeName: "Remidy 1",
      astrologer: "Astrologer 1",
      customerName: "Customer 1",
      customerNumber: "1234567890",
      price: "$100"
    },
    {
      _id: "2",
      categoryTypeName: "Remidy 2",
      astrologer: "Astrologer 2",
      customerName: "Customer 2",
      customerNumber: "9876543210",
      price: "$150"
    },
    {
      _id: "3",
      categoryTypeName: "Remidy 3",
      astrologer: "Astrologer 3",
      customerName: "Customer 3",
      customerNumber: "5555555555",
      price: "$200"
    }
  ]);

  const handleOpen = (rowData, type) => {
    setOpen(true);
    setRemidyId(rowData._id);
    setRemidyName(rowData.categoryTypeName);
    setModalType(type); // Set the modal type as "view" or "edit"
  };

  const handleClose = () => {
    setRemidyId("");
    setRemidyName("");
    setOpen(false);
  };

  const validateForm = () => {
    let isValid = true;
    if (!remidyName) {
      handleError("remidyName", "Please enter Remidy name");
      isValid = false;
    }
    return isValid;
  };

  const handleError = (input, value) => {
    setError((prev) => ({ ...prev, [input]: value }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // Perform the save or update action
      const updatedData = expertiesData.map((remidy) =>
        remidy._id === remidyId ? { ...remidy, categoryTypeName: remidyName } : remidy
      );
      setExpertiesData(updatedData); // Update state with the new data

      Swal.fire("Success", "Remidy updated successfully", "success");
      handleClose(); // Close the modal after submit
    }
  };

  const displayTable = () => {
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <MaterialTable
            title="List Of Booked Remidies"
            data={expertiesData}
            columns={[
              {
                title: "S.No",
                editable: "never",
                render: (rowData) => expertiesData.indexOf(rowData) + 1,
              },
              { title: "Remidy Name", field: "categoryTypeName" },
              { title: "Astrologer Name", field: "astrologer" },
              { title: "Customer Name", field: "customerName" },
              { title: "Customer Number", field: "customerNumber" },
              { title: "Price", field: "price" },
            ]}
            options={propStyles.tableStyles}
            style={{ fontSize: "1.0rem" }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit details",
                // onClick: (event, rowData) => handleOpen(rowData, "edit"),
              },
              {
                icon: "visibility",
                tooltip: "View details",
                onClick: (event, rowData) => handleOpen(rowData, "view"),
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  };

  const viewModal = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <div className={classes.headingContainer}>
                <div className={classes.heading}>View Remidy</div>
              </div>
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Remidy Name"
                value={remidyName}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Astrologer Name"
                value={expertiesData.find((remidy) => remidy._id === remidyId)?.astrologer}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Customer Name"
                value={expertiesData.find((remidy) => remidy._id === remidyId)?.customerName}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Customer Number"
                value={expertiesData.find((remidy) => remidy._id === remidyId)?.customerNumber}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                className={classes.closeButton}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const editModal = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <div className={classes.headingContainer}>
                <div className={classes.heading}>Edit Remidy</div>
              </div>
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                label="Remidy Name"
                value={remidyName}
                onChange={(e) => setRemidyName(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!error.remidyName}
                helperText={error.remidyName}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <div onClick={handleSubmit} className={classes.submitbutton}>
                Submit
              </div>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <div onClick={handleClose} className={classes.denyButton}>
                Cancel
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className={classes.container}>
      {!expertiesData.length ? (
        <CircularProgress />
      ) : (
        <div className={classes.box}>
          {displayTable()}
          {modalType === "view" ? viewModal() : editModal()}
        </div>
      )}
    </div>
  );
};

export default DisplayBookedRemidies;
