import React, { useEffect, useState } from "react";
import { useStyles, propStyles } from "../../assets/styles.js";
import { Avatar, Grid, FormControl, InputLabel, Select, DialogContent, Dialog, MenuItem, TextField, CircularProgress } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from 'moment';
import axios from 'axios';  // Ensure you have axios installed

const CustomerNotification = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [time, setTime] = useState("");
  const [icon, setIcon] = useState({ file: '', bytes: null });
  const [file, setFile] = useState(null);
  const [notificationId, setNotificationId] = useState('');
  const [customerNotificationData, setCustomerNotificationData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Replace static data with API call here
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // Make your API request here
        const response = await axios.get("https://api.example.com/notifications"); // Replace with your actual API endpoint
        setCustomerNotificationData(response.data);  // Assuming the API response contains the data as an array
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleOptionChange = (e) => {
    setStatus(e.target.value);
  };

  const handleOpen = (rowData) => {
    setOpen(true);
    setNotificationId(rowData?._id);
    setStatus(rowData.status);
    setTitle(rowData.title);
    setDescription(rowData.description);
    setCustomerType(rowData.customerType);
    setRecipients(rowData.recipients);
    setTime(rowData.sentAt);
    setIcon({ file: rowData.image, bytes: rowData.imageSize });
  };

  const handleError = (input, value) => {
    setError((prev) => ({ ...prev, [input]: value }));
  };

  const handleIcon = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIcon({
        file: URL.createObjectURL(e.target.files[0]),
        bytes: e.target.files[0],
      });
      handleError("icon", null);
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    // Simulating API call for updating notification
    setLoading(true);
    setTimeout(() => {
      setOpen(false);
      alert("Notification updated successfully!");  // Mock success message
      setLoading(false);
    }, 1000);  // Simulated delay
  };

  const handleClickOpen = (rowData) => {
    Swal.fire({
      title: "Are you sure to Change the Status?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const newStatus = rowData.status === "Active" ? "Inactive" : "Active";
        alert(`Status changed to: ${newStatus}`);  // Mock status update
      }
    });
  };

  const handleClose = () => {
    setFile("");
    setOpen(false);
  };

  const reverseData = Array.isArray(customerNotificationData) ? customerNotificationData.slice().reverse() : [];

  return (
    <div className={classes.container}>
      {
        loading ? <CircularProgress /> : (
          <div className={classes.box}>
            {displayTable()}
            {editModal()}
          </div>
        )
      }
    </div>
  );

  function displayTable() {
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <MaterialTable
            title="Customer Notifications"
            data={reverseData}
            columns={[
              {
                title: "S.No",
                editable: "never",
                render: (rowData) =>
                  Array.isArray(reverseData) ? reverseData.indexOf(rowData) + 1 : "N/A",
              },
              { title: "Title", field: "title" },
              { title: "Customer Type", field: "customerType" },
              { title: "Description", field: "description" },
              {
                title: 'Sent At',
                field: 'sentAt',
                render: (rowData) => moment(rowData.sentAt).format('YYYY-MM-DD HH:mm:ss'),
              },
              {
                title: "Image",
                field: "image",
                render: (rowData) => (
                  <Avatar
                    src={rowData.image}
                    style={{ width: 50, height: 50 }}
                    variant="square"
                  />
                ),
              },
              {
                title: "Status",
                field: "status",
                render: (rowData) => (
                  <div
                    className={classes.statusButton}
                    style={{
                      backgroundColor: rowData.status === "Active" ? "#90EE90" : "#FF7F7F",
                    }}
                    onClick={() => handleClickOpen(rowData)}
                  >
                    {rowData.status}
                  </div>
                ),
              },
            ]}
            options={propStyles.tableStyles}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Notification",
                onClick: (event, rowData) => handleOpen(rowData),
              },
              {
                icon: "delete",
                tooltip: "Delete Notification",
                onClick: (event, rowData) => {
                  alert(`Notification "${rowData?.title}" deleted!`);  // Mock delete action
                },
              },
              {
                icon: () => (
                  <div className={classes.addButton}>
                    <AddCircleRounded />
                    <div className={classes.addButtontext}>Add New</div>
                  </div>
                ),
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => navigate("/addCustomerNotification", { state: { type: "customer" } }),
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }

  function editModal() {
    const showEditForm = () => {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <div className={classes.headingContainer}>
              <div className={classes.heading}>Edit Notification</div>
            </div>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Enter Title"
              error={!!error.title}
              helperText={error.title}
              value={title}
              onFocus={() => handleError("title", null)}
              onChange={(event) => setTitle(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Select Status</InputLabel>
              <Select
                labelId="select-label"
                value={status}
                onChange={handleOptionChange}
                variant="outlined"
                error={!!error.status}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
              <div className={classes.errorstyles}>{error.status}</div>
            </FormControl>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Select Customer Type</InputLabel>
              <Select
                labelId="select-label"
                value={customerType}
                onChange={(e) => setCustomerType(e.target.value)}
                variant="outlined"
                error={!!error.customerType}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Old">Old</MenuItem>
              </Select>
              <div className={classes.errorstyles}>{error.customerType}</div>
            </FormControl>
          </Grid>

          <Grid item lg={4} sm={6} md={6} xs={6} className={classes.uploadContainer}>
            <label className={classes.uploadImageButton}>
              Upload Image
              <input onChange={handleIcon} hidden accept="image/*" type="file" />
            </label>
            <div className={classes.errorstyles}>{error.icon}</div>
          </Grid>
          <Grid item lg={2} sm={6} md={2} xs={6}>
            <Avatar src={icon.file} style={{ width: 56, height: 56 }} />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Enter Description"
              error={!!error.description}
              helperText={error.description}
              value={description}
              onFocus={() => handleError("description", null)}
              onChange={(event) => setDescription(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Enter Recipients"
              error={!!error.recipients}
              helperText={error.recipients}
              value={recipients}
              onFocus={() => handleError("recipients", null)}
              onChange={(event) => setRecipients(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <TextField
              label="Enter Time"
              error={!!error.time}
              helperText={error.time}
              value={time}
              onFocus={() => handleError("time", null)}
              onChange={(event) => setTime(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <button className={classes.submitButton} onClick={handleSubmit}>
              Submit
            </button>
          </Grid>
        </Grid>
      );
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          {showEditForm()}
        </DialogContent>
      </Dialog>
    );
  }
};

export default CustomerNotification;
